<template>
    <div class="layout">

        <div class="layout-header">
            <div class="title">蔬享智慧农业</div>
            <div style="flex: 1;height: 40px">

            </div>
            <header-avatar></header-avatar>
        </div>

        <div class="layout-body">
            <div class="sider">
                <recursion-menu :theme="theme" :menuData="menuData"/>
            </div>

            <div class="content">
                <router-view ref="page" >
                    <router-view></router-view>
                </router-view>
            </div>
            <!--      <a-layout-footer style="padding: .29rem 0" class="copyright">-->
            <!--        <global-footer :copyright="copyright"/>-->
            <!--      </a-layout-footer>-->
        </div>
    </div>
</template>

<script>
    import HeaderAvatar from "./HeaderAvatar";
    import RecursionMenu from "../../components/menu/RecursionMenu";
    import Setting from '@/components/setting/Setting'
    import {mapState, mapMutations} from 'vuex'
    import {triggerWindowResizeEvent} from '@/utils/common'

    const minHeight = window.innerHeight - 64 - 24 - 66

    let menuData = []

    export default {
        name: 'GlobalLayout',
        components: {HeaderAvatar,RecursionMenu},
        data() {
            return {
                minHeight: minHeight + 'px',
                collapsed: false,
                menuData: menuData
            }
        },
        computed: {
            paddingLeft() {
                return this.fixSiderbar && this.layout === 'side' && !this.isMobile ? `${this.sidebarOpened ? 256 : 80}px` : '0'
            },
            ...mapState({
                sidebarOpened: state => state.setting.sidebar.opened,
                isMobile: state => state.setting.isMobile,
                theme: state => state.setting.theme,
                layout: state => state.setting.layout,
                copyright: state => state.setting.copyright,
                fixSiderbar: state => state.setting.fixSiderbar,
                fixHeader: state => state.setting.fixHeader,
                settingBar: state => state.setting.settingBar.opened
            })
        },
        methods: {
            ...mapMutations({setSidebar: 'setting/setSidebar'}),
            toggleCollapse() {
                this.collapsed = !this.collapsed
                this.setSidebar(!this.collapsed)
                triggerWindowResizeEvent()
            },
            onDrawerChange(show) {
                this.collapsed = show
            },
            onMenuSelect() {
                this.toggleCollapse()
            }
        },
        beforeCreate() {
            let routers = this.$db.get('USER_ROUTER')
            menuData = routers.find((item) => item.path === '/').children.filter((menu) => {
                console.log(menu.name + ":" + menu.hidden)
                return !menu.hidden
                let meta = menu.meta
                if (typeof meta.isShow === 'undefined') {
                    return true
                } else return meta.isShow
            })
        }
    }
</script>

<style lang="less" scoped>
    .setting {
        background-color: #1890ff;
        color: #fff;
        border-radius: 5px 0 0 5px;
        line-height: 40px;
        font-size: 22px;
        width: 40px;
        height: 40px;
        box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
    }

    .fixed-header-content {
        margin: 60px 0px 20px !important;
        /*border-radius: 5px;*/
        /*box-shadow:  0 0 10px rgba(0,0,0,0.2);*/
        overflow: hidden;
    }


    .layout {
        position: fixed;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background: transparent;
    }

    .layout-header {
        background: rgba(255, 255, 255, 0.9);
        /*box-shadow: 0 0 5px #666;*/
        display: flex;
        align-items: center;
        padding-right: 30px;
        z-index: 2;
        font-weight: 500;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        box-sizing: content-box;
        margin-bottom: -1px;

        .title{
            width: 220px;
            height: 55px;
            font-size: 20px;
            /*background: #001529;*/
            color: #2ca338;
            padding-left: 40px;
            line-height: 55px;
        }
    }

    .layout-body {
        flex: 1;
        display: flex;
        background: transparent;
        overflow-y: auto;

        .sider {
            width: 220px;
            height: 100%;
            overflow-y: auto;
            background: #001529;
            box-shadow: 0 0 5px #888;
            padding-top: 20px;
        }

        .content {
            flex: 1;
            height: 100%;
            overflow-y: auto;
        }
    }

</style>

