import LoginPage from '../views/login/Login'
import HomePage from '../views/home/HomePage'

export default {
  // apiServer:'http://localhost:8080/',
  apiServer:'https://demo-smart-plant.bodaiot.com/api/',
  // apiServer:'http://badou.bodaiot.com:8080/',
  theme: 'dark',
  layout: 'side',
  logo:require("../assets/img/logo_color.png"),
  loginTitle: '蔬享智慧农业',
  systemName: '蔬享智慧农业',
  copyright: '蔬享智慧农业',
  multipage: false,
  fixSiderbar: true,
  fixHeader: true,
  color: '#27d85a',

  loginPage:LoginPage,
  homePage:HomePage
}

