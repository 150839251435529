<template>
    <a-locale-provider :locale="chinese">
        <div id="app" class="app">
<!--            <img src="@/assets/img/bg_login.jpg" class="app-bg"/>-->
            <router-view/>
        </div>
    </a-locale-provider>
</template>

<script>
    import enquireScreen from './utils/device'
    import chinese from 'ant-design-vue/lib/locale-provider/zh_CN'
    import 'moment/locale/zh-cn'

    export default {
        data() {
            return {
                chinese
            }
        },
        created() {
            let _this = this
            enquireScreen(isMobile => {
                _this.$store.commit('setting/setDevice', isMobile)
            })
        }
    }
</script>
<style lang="less">
    .app {
        height: 100%;
    }

    .clickable {
        cursor: pointer;
        border-radius: 5px;
    }

    .clickable:hover {
        opacity: 0.8;
    }


    i {
        font-size: .95rem;
    }

    .app-bg{
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        object-fit:cover;
        opacity: 1;
        z-index: -1;
    }


    .drawer-bootom-button {
        position: absolute;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e8e8e8;
        padding: 10px 16px;
        text-align: right;
        left: 0;
        background: #fff;
        border-radius: 0 0 2px 2px;
        z-index: 10;
    }

</style>
