<template>
    <div class="data-item">
        <div>
            <div class="data-title">{{title}}</div>
            <img class="data-icon" :src="icon"/>
        </div>
        <div class="data-value">{{value}}</div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props:["title","icon","value"]
    }
</script>

<style lang="less" scoped>
    .data-item{
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        padding: 0 30px;
        color: #FFF;

        .data-icon {
            width: 35px;
            height: 35px;
            margin-top: 15px;
            opacity: 0.8;
        }

        .data-title {
            font-size: 16px;
            opacity: 0.9;
            align-self: start;
            font-weight: 500;
        }

        .data-value {
            font-size: 56px;
            font-weight: 600;
        }

    }


</style>
