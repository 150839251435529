<template>
  <page-content>

    <a-row :gutter="[16,16]">
      <a-col :span="10">
        <StatisticsData style="background: #D9F1FF; color: #32CD32;"
                        title="在线设备数"
                        :icon="require('../../assets/home/ic_home_online.png')"
                        :value="statistics.onlineDeviceNum" >
          <OnlinePie ref="onlinePie"></OnlinePie>
        </StatisticsData>
      </a-col>
      <a-col :span="7">
        <StatisticsData style="background: #32CD32; color: #FFF;"
                        title="设备总数"
                        :icon="require('../../assets/home/ic_home_device.png')"
                        :value="statistics.totalDeviceNum" >
        </StatisticsData>
      </a-col>
      <a-col :span="7">
        <StatisticsData style="background: #00BFFF; color: #FFF"
                        title="用户总数"
                        :icon="require('../../assets/home/ic_home_user.png')"
                        :value="statistics.totalUserNum" >
        </StatisticsData>
      </a-col>

      <a-col :span="24">
        <AMap class="map-area" ref="bmap"></AMap>
      </a-col>

    </a-row>

  </page-content>
</template>
<script>


  import { mapState } from "vuex";

  import AMap from "./_components/AMap"
  import StatisticsData from "./_components/StatisticsData";
  import OnlinePie from "./_components/OnlinePie";

  export default {
    name: "HomePage",
    components: {
      StatisticsData,
      OnlinePie,
      AMap
    },
    data() {
      return {
        statistics: {
          totalDeviceNum: "--",
          onlineDeviceNum: "--",
          totalUserNum: "--",
        },

      };
    },
    computed: {
      ...mapState({
        multipage: (state) => state.setting.multipage,
        user: (state) => state.account.user,
        vendor: (state) => state.account.vendor,
      }),
      avatar() {
        return `static/avatar/${this.user.avatar}`;
      },
    },

    mounted() {

      this.$get("admin/home/statistics").then((r) => {
        let data = r.data;
        this.statistics = data;
        this.$refs.onlinePie.updateOnlinePie(data.onlineDeviceNum, data.totalDeviceNum - data.onlineDeviceNum)
      })

      setTimeout(()=>{
        this.getMapData()
      },1000)


    },
    methods:{
      getMapData(){
        this.$refs.bmap.update([],[])
        this.$get("admin/home/deviceList").then(res=>{
          let onlineData = []
          let offlineData = []
          res.data.forEach(device=>{
            if(device.address){
              let item = {
                ...device,
                value:[device.longitude,device.latitude]
              }
              if(device.status==1){
                onlineData.push(item)
              }else{
                offlineData.push(item)
              }

              setTimeout(()=>{
                this.$refs.bmap.update(onlineData,offlineData)
              },1000)

            }

          })
        })
      }
    }
  };
</script>
<style lang="less" scoped>
  .map-area {
    border-radius: 5px;
    overflow: hidden;
  }

</style>
